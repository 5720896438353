:root{
    @each $name, $value in $bolt-font{
        --bolt-font-#{$name}: #{$value};
    }
    @each $name, $value in $bolt-spacing{
        --bolt-spacing-#{$name}: #{$value};
    }
    @each $name, $value in $bolt-colors{
        --bolt-color-#{$name}: #{$value};
        @if str-index($name, 'brand-') {
            --bolt-brand-color-#{str-replace($name, "brand-", "")}: #{$value};
        }
    }
}