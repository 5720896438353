.bolt-footer {
  p {
    margin-bottom: 0;

    &.bolt-heading {
      margin-top: $bolt-spacing-lg;
      margin-bottom:  $bolt-spacing-lg; 
      font-size: 0.875rem;
      line-height: 160%;
    }

    a, a:visited {
      color: $bolt-color-white;
      border-bottom-color: $bolt-color-white;
      transition: all $bolt-transition-timing-fast;

      &:hover, &:focus {
        color: $bolt-color-white;
        border-bottom-color: transparent; 
      }
    }
  }

  &-container {
    color: $bolt-color-white;
    display: block;
    background: $bolt-color-black;
    padding: $bolt-spacing-xxl $bolt-spacing-xxxl;
    font-size: $bolt-font-size-sm;

    @include media-breakpoint-down('xs'){
      padding: $bolt-spacing-lg 1rem $bolt-spacing-xl 1rem;
    }
  }

  &-disclaimer {
    color: $bolt-color-neutral-59;
  }

  &-social {
    line-height: $bolt-font-line-height-sm;
    @include media-breakpoint-up('sm'){
      text-align: right;
    }

    p {
      display: block;
      margin-bottom: $bolt-spacing-xl;
    }

    a, a:visited {
      border-bottom: 0;
      margin-right: $bolt-spacing-lg;
      margin-bottom: $bolt-spacing-lg;
      display: inline-block;
      line-height: 0;

      bolt-icon {
        display: inline-block;
        opacity: 0.6;
        transition: all $bolt-transition-timing-fast;
        &:hover {
          opacity: 1;
        }
      }

      &:focus {
        border-bottom: none;
        bolt-icon {
          opacity: 1;
        }
      }
      
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-links {
    margin-bottom: $bolt-spacing-lg;

    span {
      &::after {
        content: "|";
        margin: 0 $bolt-spacing-md;
      }
    }

    &--helper {
      color: $bolt-color-neutral-59;
    }

    &.bolt-footer-links--w-helper {
      margin-top: 0;
    }

    a, a:visited {
      color: $bolt-color-white;
      border-bottom-color: transparent; 
      transition: all $bolt-transition-timing-fast;

      &:hover, &:focus {
        color: $bolt-color-white;
        border-bottom-color: $bolt-color-white;
        transition: all $bolt-transition-timing-fast;
      }
    }
  }

  .bolt-row {
    justify-content: space-between;

    .bolt-footer-logo, .bolt-footer-logo-basic {
      margin-bottom: $bolt-spacing-xl;
    }

    .bolt-footer-logo, .bolt-footer-logo-basic, .bolt-footer-social {
      @include media-breakpoint-up('sm'){
        flex: 0 0 auto; 
        width: auto;
      }
      @include media-breakpoint-down('xs') {
        flex-basis: 100%;
      }
    }
  }
}