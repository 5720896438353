/* Alerts */

/* Override Bootstrap mixin 'mixins/_alert.scss' to underline and not darken links */
.alert .alert-link, .alert a {
  color:inherit;
  text-decoration: underline;
  &:hover, &:focus {
    color: darken($body-color, 20%);
  }
}

/* Accordions */
ngb-accordion {
  &.accordion > .card .card-header{
    margin-bottom:0;
  }
  .card-header {
    padding:0;
    background:none;
    //background-color:#ededed;
    border:none;
    border-radius:0;

    h5 {
      font-size:1rem;
      font-weight: $font-weight-normal;
    }

    button {
      display:block;
      width:100%;
      text-align:left;
      border-radius:0.25rem 0.25rem 0 0;
      position: relative;
      padding:.55rem .83rem .55rem 2.5rem;
      color:#346094;
      background-color:#ededed;
      border: $card-border-width solid $card-border-color;

      &:before {
          position:absolute;
          top:50%;
          left:.8rem;
          margin-top:-.25rem;
          content:"";
          display:block;
          border-left: .45em solid transparent;
          border-right: .45em solid transparent;
          border-top: 0;
          border-bottom: .45em solid;
      }

      &:hover, &:focus {
        background-color:#dbdbdb;
        text-decoration:none;
        color:#346094;
      }

      &.collapsed{
        border-radius:0.25rem;
        &:before{
          position:absolute;
          top:50%;
          left:.8rem;
          margin-top:-.25rem;
          content:"";
          display:block;
          border-left: .45em solid transparent;
          border-right: .45em solid transparent;
          border-bottom: 0;
          border-top: .45em solid;
        }
      }
    }
  }

  .card {
    border:none;
    & + .card{
      margin-top:5px;
    }
  }

  .card-body {
    border:$card-border-width solid $card-border-color;
    border-top:none;
    border-radius:0 0 0.25rem 0.25rem;
    & + .card-header{
      margin-top:5px;
    }
  }
}

/* Buttons */

/*.btn-outline-secondary {
  color: $btn-primary-bg;
  border-color:$btn-primary-bg;
  &:hover, &:focus{
    color: $btn-primary-bg;
    border-color: darken($btn-primary-bg, 25%);
    background-color:rgba(0,0,0,.12);
  }
}*/

.btn-group .btn-secondary.active {
    color: #fff;
    background-color: #404040;
    border-color: #404040;
}

/* Form Groups */
.form-group{
  margin-bottom:1.75rem;
}

/* Form labels */
label{
  font-weight: $font-weight-bold;
  max-width:100%;
  margin-bottom:.25rem;
}

.radio label,.checkbox label,.form-check-label  {
    font-weight: 300;
}


/* Text Fields (form-control) */
.form-control::-webkit-input-placeholder {
    font-style:italic;
}

.form-control::-moz-placeholder {
    font-style:italic;
}

.form-control:-ms-input-placeholder {  
    font-style:italic;
}

/* Checkboxes and Radio Button Inputs */
.form-check-input {
  margin-right:.5rem;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: #888;
    background: #fff;
    border-color: #d8d8d8;    
}

/* Legend for radio/checkboxes */
legend {
  font-weight: $font-weight-bold;
  //font-size:1rem;
  margin-bottom:.25rem;
}

/* Tables */
caption{
  caption-side: initial;
}
.table th, .table td {
  padding: 0.5rem;
}

// progress bar
.progress-bar{
  height:1.5rem;
  background-color: $brand-color-teal-medium;
}
.progress{
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

// inputs--force font-family due to a default of 'sans-serif' in _normalize
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-base;
}

// pagination
.pagination{
  border-radius:0;
}
.page-item{
  &.disabled{
    cursor:not-allowed;
  }
  &.active .page-link {
    cursor:default;
    color:#333;
    background:none;
    border:none;
    font-weight:700;
  }
  .page-link{
    background: none;
    border: none;
    border-radius:.25rem;
    span{
      display: none;
    }
  }
  &:last-child .page-link:after {
    content: '›';
  }
  &:first-child .page-link:after {
    content: '‹';
  }
}

// tabs
.tab-content {
  padding: 1.5rem;
  border: solid 1px #d8d8d8;
  border-top: none;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  border: 1px solid #d8d8d8;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-tabs > li > a {
  transition: 0.2s all ease-out;
  background-color: #ededed;
  border-bottom: solid 1px #d8d8d8;
  color: #346094;
  &:hover{
    background-color:#e0e0e0;
  }
}
@include media-breakpoint-up(lg){
  .nav-tabs>li {
    margin-right: .8rem;
  }
}
.nav-tabs {
    padding-left: .5rem;
}

// alerts
.alert-info svg path{
  fill:theme-color('info'); //$alert-info-text;
}
.alert-danger svg path{
  fill:theme-color('danger'); //$alert-danger-text;
}
.alert-success svg path{
  fill:theme-color('success'); //$alert-success-text;
}
.alert-warning svg path{
  fill:theme-color('warning'); //$alert-warning-text;
}
.alert svg{
  height:1.5rem;
  width:1.5rem;
  margin-right:1rem;
}