@use "sass:math";
@use "sass:string";
// output configuration
//
$should-output-css-vars: false;
$demo-selectors: false !default;
$load-gotham: true !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           $bolt-spacing-xl !default;

// Container padding
$container-padding-x: math.div($grid-gutter-width, 2) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-breakpoints: (
  xs: $bolt-breakpoint-xs,
  sm: $bolt-breakpoint-sm,
  md: $bolt-breakpoint-md,
  lg: $bolt-breakpoint-lg,
  xl: $bolt-breakpoint-xl
) !default;

// $dark-background-selector is a selector representing dark backgrounds that need white text
$bg-colors: '';
@each $name, $value in $bolt-backgrounds{
    @if (color-contrast($value, $bolt-color-black, $bolt-color-white) == $bolt-color-white){
        $bg-colors: string.insert($bg-colors, ", .bolt-background-#{$name}", string.length($bg-colors) + 1);
    }
}
$dark-background-selector: string.slice($bg-colors, 2, string.length($bg-colors));


$component-backgrounds: () !default;
