//
// Wait spinner, progress indicator

.progress-spinner,.progress-spinner-inverse {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0icGIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIj4gICAgPGRlZnM+PHN0eWxlPi5wMXtmaWxsOnVybCgjbGluZWFyLWdyYWRpZW50KTt9LnAye2ZpbGw6IzMzNWY5Njt9PC9zdHlsZT4gICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0ibGluZWFyLWdyYWRpZW50IiB5MT0iMTAwIiB4Mj0iMjAwIiB5Mj0iMTAwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+ICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwLjEiIHN0b3AtY29sb3I9IiMzMzVmOTYiLz4gICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjAuNCIgc3RvcC1jb2xvcj0iIzM0NjA5NCIgc3RvcC1vcGFjaXR5PSIwLjIiLz4gICAgICAgIDwvbGluZWFyR3JhZGllbnQ+ICAgIDwvZGVmcz4gICAgPHBhdGggY2xhc3M9InAxIiBkPSJNMTAwLDBBMTAwLDEwMCwwLDEsMCwyMDAsMTAwLDEwMCwxMDAsMCwwLDAsMTAwLDBabTAsMTc1YTc1LDc1LDAsMSwxLDc1LTc1QTc1LDc1LDAsMCwxLDEwMCwxNzVaIi8+ICAgIDxwYXRoIGNsYXNzPSJwMiIgZD0iTTEwMCwyNVYwQTEwMCwxMDAsMCwwLDAsMCwxMDBIMjVBNzUsNzUsMCwwLDEsMTAwLDI1WiIvPjwvc3ZnPg==);
    display:inline-block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    position: relative;
    background-size: contain;
    animation: spinner-ani 1s infinite linear;
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.progress-spinner-inverse {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0icHciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIj4gICAgPGRlZnM+PHN0eWxlPi5wMXtmaWxsOnVybCgjbGluZWFyLWdyYWRpZW50KTt9LnAye2ZpbGw6I2ZmZjt9PC9zdHlsZT4gICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0ibGluZWFyLWdyYWRpZW50IiB5MT0iMTAwIiB4Mj0iMjAwIiB5Mj0iMTAwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+ICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwLjEiIHN0b3AtY29sb3I9IiNmZmYiLz4gICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjAuNCIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIwLjIiLz4gICAgICAgIDwvbGluZWFyR3JhZGllbnQ+ICAgIDwvZGVmcz4gICAgPHBhdGggY2xhc3M9InAxIiBkPSJNMTAwLDBBMTAwLDEwMCwwLDEsMCwyMDAsMTAwLDEwMCwxMDAsMCwwLDAsMTAwLDBabTAsMTc1YTc1LDc1LDAsMSwxLDc1LTc1QTc1LDc1LDAsMCwxLDEwMCwxNzVaIi8+ICAgIDxwYXRoIGNsYXNzPSJwMiIgZD0iTTEwMCwyNVYwQTEwMCwxMDAsMCwwLDAsMCwxMDBIMjVBNzUsNzUsMCwwLDEsMTAwLDI1WiIvPjwvc3ZnPg==);
}

.progress-spinner-small {
	width:1.35rem;
	height:1.35rem;
}

.btn-progress{
  position: relative;
  &.btn-lg[disabled] .progress-spinner-small{
    margin:0;
    top:12px;
    left:calc(50% - 11px)
  }
  &[disabled] .progress-spinner-small{
    display:inline-block;
  }
  .progress-spinner-small{
    display:none;
    margin:-4px 0 -6px 0;
    position: absolute;
    top:10px;
    left:calc(50% - 8px);
  }
}
@-webkit-keyframes spinner-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}