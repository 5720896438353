
/*

*/

//$theme: comfortable;
$theme: cozy;
//$theme: compact;

$font: brand;
//$font: standard;

$rem-size:0;
$font-family-sans-serif: '';
$font-family-serif: '';

@if $theme == comfortable {
  $rem-size:18;
} @else if $theme == cozy {
  $rem-size:16;
} @else if $theme == compact {
  $rem-size:14;
} @else {
  $rem-size:18;
}

@if $font == brand {
  
  $font-family-sans-serif: "Proxima-Nova","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  $font-family-serif:      "Chronicle",Georgia,"Times New Roman","Roboto Slab",serif;

} @else if $font == standard {

  $font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, Roboto, sans-serif;
  $font-family-serif:      Georgia, "Times New Roman", "Roboto Slab", serif;

}