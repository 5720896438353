@import 'font-definitions';

.bolt-typography{
    font-family: $bolt-font-family-sans-serif;
    font-size: 100%;
    body{
        font-size: $bolt-font-size-md;
        line-height: 170%;
        color: $bolt-color-black;
    }
    p{
        margin: 0;
        padding: 0;
        margin-bottom: $bolt-spacing-lg; 
    }
    a,a:visited{
        border-bottom: 1px solid rgba(0,0,0,0);
        color: $bolt-text-link-default;
        text-decoration: none;
        transition: color $bolt-transition-timing-fast, border $bolt-transition-timing-fast, box-shadow $bolt-transition-timing-med;
        &:active,&:focus{
            color: $bolt-text-link-default;
            border-bottom: 1px solid $bolt-text-link-default;
        }
        &:hover {
          border-color: transparent;
        }
    }
    a:focus{
        box-shadow: $bolt-interactive-focus;
        outline: none;
        border-color: transparent;
    }
    p a, li a, nav a{
        &,&:visited{
            border-bottom: 1px solid $bolt-text-link-default;
        }
    }
    strong,b {
        font-weight: $bolt-font-weight-bold;
    }
    em,i{
        font-style: italic;
    }
    sub, sup{
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sup{
        top: -0.5em;
    }
    sub{
        bottom: -0.25em;
    }
    .bolt-heading-lg, .bolt-heading-md, .bolt-heading-sm, .bolt-heading {
        + .bolt-heading-lg, + .bolt-heading-md, + .bolt-heading-sm {
            margin-top: $bolt-spacing-xxl;
        }
    }
    p ~ .bolt-heading-lg, p ~ .bolt-heading-md, p ~ .bolt-heading-sm {
            margin-top: $bolt-spacing-xxl; 
    }
    a ~ ul,p ~ ul, p ~ ol{
        margin-bottom: $bolt-spacing-xl;
    }
    .bolt-heading, p.bolt-heading {
        margin-bottom: $bolt-spacing-xs;
    }

    ul { 
        list-style-type: disc; 
        list-style-position: inside; 
        margin: 0 0 $bolt-spacing-xxl $bolt-spacing-md;
    }
    ol { 
        list-style-type: decimal; 
        list-style-position: inside; 
        margin: 0 0 $bolt-spacing-xxl $bolt-spacing-md;
    }
    ol[start]{
        margin-top: $bolt-spacing-xl;
    }
    li{
      margin-bottom: $bolt-spacing-md;
      list-style-position: inside;
      text-indent: -($bolt-spacing-lg+$bolt-spacing-xs);
      padding-left: $bolt-spacing-xl;
      line-height:($bolt-spacing-lg+$bolt-spacing-xs);
      @include media-breakpoint-down('lg'){
          padding-left: $bolt-spacing-lg;
      }
    }
    ul ul, ol ul { 
        list-style-type: circle; 
        list-style-position: inside; 
        margin: $bolt-spacing-xs 0 $bolt-spacing-lg $bolt-spacing-xs;
    }
    ol ol, ul ol { 
        list-style-type: lower-latin; 
        list-style-position: inside; 
        margin-left: 15px; 
    }
    ul ul li, ol ul li {
      margin-bottom: $bolt-spacing-sm;
    }
}

.bolt{
    //fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size)
    &-heading{
        /*&-xl{
            font-weight: $bolt-font-weight-regular;
            @include fluid-type(320px, 1200px, 40px, 60px);
        }*/
        &-lg{
            @include heading('lg');
        }
        &-md{
            @include heading('md');
        }
        &-sm{
            @include heading('sm');
        }
        @include heading('xs', $bolt-font-weight-bold, 0);
        @each $name, $value in $bolt-backgrounds{
            @if(ends-with($name, '-medium') or ends-with($name, '-dark')){
                &-#{$name}{
                    color: $value;
                }
            }
        }


    }
    &-text{
        &-body,&-md{
            font-size: $bolt-font-size-md;
            line-height: 170%;
        }
        &-sm,&-legal{
            font-size: $bolt-font-size-sm;
            line-height: 160%;
        }
        &-lg,&-lead{
            font-size: $bolt-font-size-lg;
            line-height: 180%;
        }
    }
}