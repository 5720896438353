.bolt-typography{
    @each $name, $value in $bolt-backgrounds{
        .bolt-background-#{$name}{
            background-color: $value;

            @if (color-contrast($value, $bolt-color-black, $bolt-color-white) == $bolt-color-white){
                .bolt-waiting-indicator-wc--ellipsis div {
                    background: $bolt-color-white!important; 
                }
            }
        }
    }
}