.bolt-button-wc{
    .bolt-icon path{
        fill: currentColor;
        transition: fill $bolt-transition-timing-med;
    }
    &--primary{
        .bolt-icon path{
            fill: text-contrast($bolt-interactive-background-white, $bolt-color-black, $bolt-color-white);
        }
        #{if($demo-selectors, '&__hover,', '')}
        &:hover:not([disabled]), &:focus:not([disabled]){
            .bolt-icon path{
                fill: text-contrast(mix-blend-normal(
                    $bolt-interactive-overlay-primary-hover-light,
                    $bolt-interactive-background-white), $bolt-color-black, $bolt-color-white);
            }
        }
        #{if($demo-selectors, '&__active,', '')}
        &:active:not([disabled]){
            .bolt-icon path{
                fill: text-contrast(mix-blend-normal(
                    $bolt-interactive-overlay-primary-active-light,
                    $bolt-interactive-background-white), $bolt-color-black, $bolt-color-white);
            }
        }
    }
    &--secondary{
        #{if($demo-selectors, '&__hover,', '')}
        &:hover:not([disabled]), &:focus:not([disabled]){
            .bolt-icon path{
                fill: text-contrast(mix-blend-normal(
                    $bolt-interactive-overlay-secondary-hover-light,
                    $bolt-interactive-background-white), $bolt-color-black, $bolt-color-white);
            }
        }
        #{if($demo-selectors, '&__active,', '')}
        &:active:not([disabled]){
            .bolt-icon path{
                fill: text-contrast(mix-blend-normal(
                    $bolt-interactive-overlay-secondary-hover-light,
                    $bolt-interactive-background-white), $bolt-color-black, $bolt-color-white);
            }
        }
    }
    &--tertiary{
        #{if($demo-selectors, '&__hover,', '')}
        &:hover:not([disabled]), &:focus:not([disabled]){
            .bolt-icon path{
                fill: $bolt-interactive-background-white;
            }
        }
        #{if($demo-selectors, '&__active,', '')}
        &:active:not([disabled]){
            .bolt-icon path{
                fill: text-contrast(mix-blend-normal(
                    $bolt-interactive-overlay-secondary-hover-light,
                    $bolt-interactive-background-white), $bolt-color-black, $bolt-color-white);
            }
        }
    }
}
.bolt-button.bolt-button-wc{
    @each $name, $bolt-background-value in $bolt-backgrounds{
        @if(should-output-background($name)){
            @if(map-has-key($bolt-interactive-colors, $name)){
                $bg: map-get($bolt-interactive-colors, $name);
                $primaryTextColor: text-contrast($bg, $bolt-color-black, $bolt-color-white, "primary main color for #{$name}");

                // init to establish scope and noticeable defaults
                $primaryHover: #f00; 
                $primaryActive: #f00;
                $secondaryActive: #f00;
                $secondaryHover: #f00;

                @if(ends-with($name, '-medium') or ends-with($name, '-dark')){
                    $primaryHover: mix-blend-normal($bolt-interactive-overlay-primary-hover-dark, $bg);
                    $primaryActive: mix-blend-normal($bolt-interactive-overlay-primary-active-dark, $bg);
                    $secondaryHover: $bolt-interactive-overlay-secondary-hover-dark;
                    $secondaryActive: $bolt-interactive-overlay-secondary-active-dark;
                }
                @else{
                    $primaryHover: mix-blend-normal($bolt-interactive-overlay-primary-hover-light, $bg);
                    $primaryActive: mix-blend-normal($bolt-interactive-overlay-primary-active-light, $bg);
                    $secondaryHover: $bg;
                    $secondaryActive: mix-blend-normal($bolt-interactive-overlay-secondary-active-light, $bg);
                }
            
                .bolt-background-#{$name} &
                #{ if(str-index($name, "-medium-25"), ", &--primary.bolt-button-wc--color-#{str-replace($name, "-medium-25", "")}", "") }
                #{ if(str-index($name, "-dark"), ", &--primary.bolt-button-wc--color-#{str-replace($name, "-dark", "-on-dark")}", "") }
                {
                    background: $bg;
                    color: $primaryTextColor;
                    //text-decoration: none;
                    .bolt-icon path{
                        fill: $primaryTextColor;
                    }
                }
                //.bolt-button-wc--color__#{$name} &{
                //    @extend .bolt-background-#{$name} &;
                //}
                //@debug str-index($name, "-medium-25");
                .bolt-background-#{$name} &--primary
                #{ if(str-index($name, "-medium-25"), ", &--primary.bolt-button-wc--color-#{str-replace($name, "-medium-25", "")}", "") }
                #{ if(str-index($name, "-dark"), ", &--primary.bolt-button-wc--color-#{str-replace($name, "-dark", "-on-dark")}", "") }
                {
                    background: $bg;
                    border-bottom: 1px solid rgba(0, 0, 0, 0);
                    .bolt-icon path{
                        fill: $primaryTextColor
                    }
                    #{if($demo-selectors, '&__visited,', '')}
                    &:visited:not([disabled]){
                        background: $bg;
                        color: $primaryTextColor;
                        .bolt-icon path{
                            fill: text-contrast($primaryHover,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                    #{if($demo-selectors, '&__hover,', '')}
                    &:hover:not([disabled]), &:focus:not([disabled]){
                        background: $primaryHover;
                        color: text-contrast($primaryHover,
                            $bolt-color-black,
                            $bolt-color-white,
                            "primary :hover color for #{$name}");
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: text-contrast($primaryHover,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                    #{if($demo-selectors, '&__active,', '')}
                    &:active:not([disabled]),&:active:hover:not([disabled]){
                        background: $primaryActive;
                        color: text-contrast($primaryActive,
                            $bolt-color-black,
                            $bolt-color-white,
                            "primary :active color for #{$name}");
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: text-contrast($primaryActive,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                }

                .bolt-background-#{$name} &--secondary, .bolt-background-#{$name} a#{&}--secondary
                #{ if(str-index($name, "-medium-25"), ", &--secondary.bolt-button-wc--color-#{str-replace($name, "-medium-25", "")}", "") }
                //#{ if(str-index($name, "-dark"), ", &--secondary.bolt-button-wc--color-#{$name}", "") }
                {
                    background:rgba(0,0,0,0);
                    color: text-contrast($bolt-background-value,
                        $bg,
                        $bolt-color-white,
                        "secondary main color for #{$name}");
                    border:1px solid color-contrast($bolt-background-value,
                        $bg,
                        $bolt-color-white);
                    //text-decoration: none;

                    $secondaryHoverBlended: mix-blend-normal($secondaryHover, $bolt-background-value);

                    //@debug "blended background for #{$name} is #{$secondaryHoverBlended}";

                    #{if($demo-selectors, '&__hover,', '')}
                    &:hover:not([disabled]), &:focus:not([disabled]){
                        background: $secondaryHover;
                        color: text-contrast($secondaryHoverBlended,
                            $bolt-color-black,
                            $bolt-color-white,
                            "secondary :hover color for #{$name}");
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: text-contrast($secondaryHoverBlended,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                    #{if($demo-selectors, '&__active,', '')}
                    &:active:not([disabled]),&:active:hover:not([disabled]){
                        background: $secondaryActive;
                        color: color-contrast($secondaryActive,
                            $bolt-color-black,
                            $bolt-color-white);
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: color-contrast($secondaryActive,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                }
                .bolt-background-#{$name} &--secondary{
                    .bolt-icon path{
                        fill: text-contrast($bolt-background-value,
                            $bg,
                            $bolt-color-white);
                    }
                }

                .bolt-background-#{$name} &--tertiary
                #{ if(str-index($name, "-medium-25"), ", &--tertiary.bolt-button-wc--color-#{str-replace($name, "-medium-25", "")}", "") }
                //#{ if(str-index($name, "-dark"), ", &--tertiary.bolt-button-wc--color-#{$name}", "") }
                {
                    background:rgba(0,0,0,0);
                    color: text-contrast($bolt-background-value,
                        $bg,
                        $bolt-color-white,
                        "tertiary main color for #{$name}");
                    border:1px solid rgba(0,0,0,0);
                    //text-decoration: none;
                    
                    $secondaryHoverBlended: mix-blend-normal($secondaryHover, $bolt-background-value);

                    //@debug "blended background for #{$name} is #{$secondaryHoverBlended}";

                    .bolt-icon path{
                        fill: text-contrast($bolt-background-value,
                            $bg,
                            $bolt-color-white);
                    }

                    #{if($demo-selectors, '&__hover,', '')}
                    &:hover:not([disabled]), &:focus:not([disabled]){
                        border: 1px solid color-contrast($bolt-background-value,
                            $bg,
                            $bolt-color-white);
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: text-contrast(
                                $bolt-background-value,
                                $bg,
                                $bolt-color-white);
                        }
                    }
                    #{if($demo-selectors, '&__active,', '')}
                    &:active:not([disabled]),&:active:hover:not([disabled]){
                        background: $secondaryHover;
                        color: text-contrast(
                            $secondaryHoverBlended,
                            $bolt-color-black,
                            $bolt-color-white,
                            "tertiary :active color for #{$name}");
                        //text-decoration: none;
                        .bolt-icon path{
                            fill: text-contrast(
                                $secondaryHoverBlended,
                                $bolt-color-black,
                                $bolt-color-white);
                        }
                    }
                }
            }
        }
    }
}