:root{
    @if($should-output-css-vars){
        --bolt-icon--fill: #{$bolt-color-black}; 
    }
}

bolt-icon:not([name$="-colored"]){
    svg{
        g, path, rect, circle{
            fill: #222;
        }
    }
}

a.bolt-remove-underline {
    &:visited,
    &:visited:focus,
    &:focus,
    &:active {
        border-bottom: 0;
    }
}

@each $name, $value in $bolt-icon-colors{
    .bolt-icon.bolt-icon-wc--color-#{$name}{
        g, path, rect, circle{
            fill: $value;
        }
    }
}

@each $name, $value in $bolt-backgrounds{
    .bolt-background-#{$name}{
        @if (color-contrast($value, $bolt-color-black, $bolt-color-white) == $bolt-color-white){
            bolt-icon:not([name$="-colored"]) svg, .bolt-icon-wc--color{
                g, path, rect, circle{
                    fill: #{$bolt-color-white};
                }
            }
            bolt-icon[name="chevron-down"].bolt-accordion{
              path{
                fill: #{$bolt-color-blue};
              }
            }
            @if($should-output-css-vars){
                --bolt-icon--fill: #{$bolt-color-white};
            }
        }
    }
}

@each $name, $value in $bolt-icon-colors{
    bolt-icon svg.bolt-icon.bolt-icon-wc--color-always-#{$name}{
        g, path, rect, circle{
            fill: $value;
        }
    }
}