@if($load-gotham){
    @font-face {
        font-family: "nw-primary";
        src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-400.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "nw-primary";
        src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-400-Italic.woff") format("woff");
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "nw-primary";
        src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-700.woff") format("woff");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "nw-primary";
        src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-700-Italic.woff") format("woff");
        font-weight: 700;
        font-style: italic;
    }
}