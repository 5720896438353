bolt-modal{
    .bolt-modal-wc--icon svg[class*="bolt-icon-wc--size-"].bolt-icon {
        height: 40px;
        width: 40px;
    }
    img {
        max-width: 100%;
    }

    .bolt-container, .bolt-container-fluid {
        padding-left: 0; 
        padding-right: 0; 
    }
}