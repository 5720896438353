/* Set root font size based on media query. This sets a base for all references to 'rem' calculations  */

html{ font-size:($rem-size * .75) + px;}

@include media-breakpoint-up(sm) {
  html{ font-size:($rem-size * .875) + px;}
}

@include media-breakpoint-up(md) {
  html{ font-size:($rem-size * 1) + px;}
}

@include media-breakpoint-up(lg) {
  html{ font-size:($rem-size * 1) + px;}
}

@include media-breakpoint-up(xl) {
  html{ font-size:($rem-size * 1) + px;}
}

/* Utility icons use a SVG sprite sheet
  Size variations are based on the overall theme size 
 */
.nw-utility-icon {
  display: inline-block;
  width: 1rem; 
  height: 1rem; 
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &-xs {
    @extend .nw-utility-icon;
    width:  .75rem;
    height: .75rem;
  }
  &-sm {
    @extend .nw-utility-icon;
    width:  .875rem;
    height: .875rem;
  }
  &-lg {
    @extend .nw-utility-icon;
    width:  1.5rem;
    height: 1.5rem;
  }
  &-xl {
    @extend .nw-utility-icon;
    width:  2rem;
    height: 2rem;
  }
}

/* Additional variant for outline that is meant for use on dark backgrounds */
.btn-outline-inverse {
    color: $btn-outline-inverse-color;
    background: none;
    border-color: $btn-outline-inverse-color;
   
    &:hover,
    &:focus {
        color: lighten($btn-outline-inverse-color,100%);
        border-color: lighten($btn-outline-inverse-color, 100%);
        background-color:rgba(0,0,0,.12);
    }
    
    &:disabled {
        background:none;
        border-color: $btn-outline-inverse-color;
        color: $btn-outline-inverse-color;
    }
}
// put the link cursor back
.btn:hover{
  cursor:pointer;
}
.btn:hover[disabled]{
  cursor:not-allowed;
}

/*
// background colors that use brand variables not defined until later
.nw-bg-white { background:#fff; }
.nw-bg-black { background:#000; color:#fff; }

.nw-bg-primary{
	background:$brand-primary;
	color:#fff;
}
.nw-bg-primary a{
  color:#fff;
  &:hover,&:focus{
    color:#e6e6e6;
  }
}
.nw-bg-secondary {
	background:$brand-secondary;
	color:#333;
}

.nw-bg-accent {
	background:$brand-accent;
	color:#fff;
}

.nw-fg-white { color:#fff; }
.nw-fg-black { color:#333; }
.nw-fg-link { color: $link-color; }

.nw-fg-primary { color:$brand-primary; }
.nw-fg-secondary { color:$brand-secondary; }
.nw-fg-accent { color:$brand-accent; }
*/

// content
.nw-hero {
    font-size: 1.8rem;
}

// tables
.nw-table-subheader {
  background-color: #b4d0db !important;
  font-weight: 700;
}
.table>thead th {
  background-color: #666;
  color: #fff;
}

.nw-field-error.alert{
  margin-top:.4rem;
  padding:.3rem;
  svg{
    width:1.2rem;
    margin-right:.4rem;
  }

}

// labels (messaging)
.nw-label {
  background-color: $brand-color-nwblue;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  padding: 2px 10px;
  font-weight: 700;
}

.nw-fieldlabel-optional::after {
  content: " (optional)";
  font-weight: 300;
  font-style: italic;
  color: #777;
}

.help-block {
    color: #777;
    font-size: .8rem;
    margin:.25rem 0 0 0;
    display: block;
}

// breadcrumbs
.nw-breadcrumbs__current { font-weight:700; }

.nw-breadcrumbs__home {
    padding-left:20px;
    height:20px;
    
    .nw-icon-home {
        position:absolute;
        left:0;
        top:-15px;
        font-size:18px;
    }
}

.nw-breadcrumbs {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top:10px;
    display: flex;
    align-items: center;
    
    //a,i { display:none; }

    a:first-of-type, // Fist link (home)
    i:first-of-type, // First chevron
    a:nth-of-type(2), // second link (collapse)
    i:nth-of-type(2), // second chevron
    a:nth-last-of-type(1), // last anchor before current
    i:last-of-type // Last chevron before current
        { display:inline-block; }
}

.nw-breadcrumbs .nw-breadcrumbs__home svg {
    height: 18px;
    margin-right:-.5rem;
}

.nw-breadcrumbs svg {
    height:12px;
    margin:0 .5rem;
}

/*
@include screensize($screen-sm) {
    .nw-breadcrumbs {
        a,i { display:inline-block; }
        
        a:nth-of-type(2), // hides collapse link
        i:nth-of-type(2) // hides collapse chevron
            { display:none; }
    }
}*/

// NW.com CTAs
.nw-small-cta{
  max-width:860px;
  margin:0 auto;
  padding:20px 0;
  font-size: 1.2em;

  .media-body{
      align-self:center;
  }
  svg {
      width: 80px;
  }
}
.nw-quote-cta{
  padding:40px 0;
}

// tbd: best place for this?
.fg-white{
  a{
      color:#fff;
  }
  a:not(.btn){
    text-decoration:underline;
  }
  svg{
      fill: #fff;
  }
}

.btn-default{
  @include button-variant(#f2f2f2, #ccc, $active-background: #404040, $active-border: #404040);
  color:$brand-color-blue;
  &:hover,&:focus{
    color:$brand-color-blue;
  }
}
.btn-group .btn-default.active{
  color:#fff;
}
.btn.active{
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
}

.nw-btn-inline{
  @include button-variant(rgba(0,0,0,0), $brand-color-blue, $hover-background: rgba(0,0,0,0.12), $hover-border: $brand-color-blue);
  color: $brand-color-blue;
  &:hover,&:focus{
    color: $brand-color-blue;
  }
}
.nw-btn-inline-inverse{
  @include button-variant(rgba(0,0,0,0), #fff, $hover-background: rgba(0,0,0,0.12), $hover-border: #fff);
  color:#fff;
}

.form-check-label,.form-radio-label{
  cursor: pointer;
}

.btn-primary.btn-progress{
  transition:0.3s;
}
.btn-primary.btn-progress.disabled, .btn-progress.btn-primary:disabled{
  background: #778;
  border-color: #778;
}

.btn:focus{
  outline: 1px solid #fff;
  box-shadow: 0 0 4px 4px #5BC6E8;
}