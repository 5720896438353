@import '../../../component-date-picker/node_modules/flatpickr/dist/flatpickr';

.bolt {
  &-date-picker-wc {
    display: flex;
    background: $bolt-color-white;
    padding: 10px 5%;
    border-top: 1px solid $bolt-color-neutral-42;
    flex-wrap: wrap;

    &--legend {
      &-menu {
        display: flex;
        margin-right: 1%;
        width: 49%;
        text-align: left;
        padding: 2px 0;

        &-icon {
          display: inline-block;
          width: 21px;
          height: 21px;
          position: relative;
          top: 2px;

          &.today {
            .date-svg {
              &.circle {
                .shape {
                  fill-opacity: 0;
                }
              }

              .outer-stroke {
                stroke: $bolt-color-rebrand-dark-gray;
              }
            }
          }

          .date-svg {
            z-index: 1;
            width: 15px;
            height: 15px;

            &.diamond {
              width: 18px;
              height: 18px;
            }

            .shape {
              fill: $bolt-color-rebrand-pale-gray;
            }

            .inner-stroke {
              stroke: $bolt-color-rebrand-pale-gray;
              fill-opacity: 0;
            }

            .outer-stroke {
              fill-opacity: 0;
              stroke: $bolt-color-white;
            }
          }
        }

        &-text {
          font-size: 15px;
          color: $bolt-color-black;
        }
      }
    }
  }
}

.flatpickr-current-month {
  height: 44px;
  padding: 0;
  width: auto;
  left: 10px;
  color: $bolt-color-black;
  font-size: 115%;
  display: flex;
  align-items: center;
  justify-content: center;

  .flatpickr-monthDropdown-months:hover + bolt-icon {
    svg {
      path {
        fill: $bolt-color-blue;
      }
    }
  }

  & > bolt-icon[name='chevron-down'] {
    position: relative;
    left: -20px;
    z-index: -1;
  }

  .numInputWrapper {
    width: auto;
    position: relative;
    left: 0;

    &:hover {
      background: none;

      bolt-icon[name='chevron-down'] {
        svg {
          path {
            fill: $bolt-color-blue;
          }
        }
      }
    }

    bolt-icon[name='chevron-down'] {
        position: absolute;
        right: 5px;
    }
  }

  input.cur-year:focus,
  .flatpickr-monthDropdown-months:focus,
  .flatpickr-monthDropdown-months:active {
    box-shadow: 0 0 0 1px $bolt-color-white, 0 0 2px 4px $bolt-color-brand-muted-blue-accent;
  }

  .flatpickr-monthDropdown-months {
    direction: ltr;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 25px;
    padding-left: 0;

    &::-ms-expand {
      display: none;
    }
  }
}

.bolt-year-dropdown {
  @extend .flatpickr-monthDropdown-months;
  padding-left: 0;
  font-size: 102%;

  &:hover {
    background: none;
  }
}

.flatpickr-innerContainer {
  width: 100%;
  margin: auto;
  padding-bottom: 10px;
}

.flatpickr-rContainer {
  margin: auto;
}

.flatpickr-months {
  background: $bolt-color-neutral-96;
  border-bottom: 1px solid $bolt-color-neutral-42;
  height: 44px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .flatpickr-month {
    height: 44px;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    height: auto;

    &:hover {
      svg {
        fill: $bolt-color-blue;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    bolt-icon{
      height: auto;
      pointer-events: none;
    }

    &.flatpickr-prev-month {
      right: 50px;
      left: auto;
      a, a:focus {
        padding: 16px 0 1px 0;
      }
    }

    &.flatpickr-next-month {
      right: 5px;
      left: auto;
      a, a:focus {
        padding: 16px 0 1px 0;
      }
    }

    &:focus {
      box-shadow: 0 0 0 1px $bolt-color-white, 0 0 2px 4px $bolt-color-brand-muted-blue-accent;
      outline: none;
    }

    &:active {
      box-shadow: none;
      outline: none;
    }
  }
}

.flatpickr-weekdays {
  border-bottom: 1px solid rgba($bolt-color-neutral-59, 0.5);
  height: 46px;
}

.flatpickr-calendar {
  border-radius: 0;
  border: 1px solid $bolt-color-neutral-42;
  width: 320px;
  box-shadow: none;
  z-index: $bolt-z-index-med !important;

  &.arrowBottom {
    margin-top: -48px;
  }

  &.arrowTop {
    margin-top: -3px;
  }

  &.open {
    box-shadow: 1px 1px 3px 1px #aaa;
    @include media-breakpoint-down('xs'){
      left: 50% !important;
      transform: translateX(-50%);

      &.animate {
        animation: none;
      }
    }
  }

  &:before,
  &:after {
    border-top: 0;
  }

  &.arrowTop:after,
  &.arrowTop:before {
    display: none;
  }
}

span.flatpickr-weekday {
  font-weight: lighter;
  color: $bolt-color-black;
}

#bolt-custom-year-select {
  width: 47px;
  margin-left: 11px;
}

.flatpickr-day {
  pointer-events: none;
  border: 0;
  font-weight: 600;
  color: $bolt-color-blue;
  margin-top: 3px;

  &:focus {
    background: none;

    .date-svg {
      .inner-stroke {
        stroke: #1E6FD2;
      }
    }
  }

  &:hover {
    background: none;
    border: 0;

    .date-svg {
      .inner-stroke {
        stroke: $bolt-color-rebrand-vibrant-blue;
        transition: all .2s ease-in-out;
      }
    }
  }

  &:active {
    color: $bolt-color-white;

    .date-svg {
      .shape {
        fill: $bolt-color-rebrand-dark-blue;
      }

      .inner-stroke {
        stroke: $bolt-color-rebrand-dark-blue;
      }
    }
  }

  &.selected {
    background: none;
    border: 0;

    .date-svg {
      .shape {
        fill: $bolt-color-rebrand-vibrant-blue;
      }

      .inner-stroke {
        stroke: $bolt-color-white;
      }
    }

    &:focus {
      background: none;
      border: 0;

      .date-svg .inner-stroke {
        stroke: #1E6FD2;
      }
    }

    &:hover {
      background: 0;
      border: 0;

      .date-svg {
        .inner-stroke {
          stroke: $bolt-color-white;
        }

        .outer-stroke {
          stroke: $bolt-color-rebrand-light-blue;
          stroke-width: 3;
        }
      }
    }
  }

  .date-svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: -1px auto auto auto;
    z-index: -1;
    width: 100%;

    .outer-stroke {
      stroke: $bolt-color-white;
      stroke-width: 3px;
      transition: all .2s ease-in-out;
    }

    .inner-stroke {
      fill: transparent;
      stroke: $bolt-color-white;
      transition: all .2s ease-in-out;
    }

    .shape {
      fill: $bolt-color-white;
      pointer-events: auto;
      transition: all .2s ease-in-out;
    }
  }

  &.prevMonthDay,
  &.nextMonthDay {
    visibility: hidden;
    pointer-events: none;
    color: $bolt-color-white;

    &:focus {
      background: transparent;
      border-color: none;
    }

    &.selected {
      background: transparent;
      border-color: none;
    }

    .date-svg {
      display: none;
    }

    &:hover {
      background: none;
      border: 0;
    }

    &.flatpickr-disabled {
      background: none;
      border: 0;
      color: $bolt-color-white;

      &:hover {
        background: none;
        border: 0;
        color: $bolt-color-white;
      }

      &:focus {
        background: none;
        border: 0;
        color: $bolt-color-white;
        .date-svg {
          .inner-stroke {
            stroke: $bolt-color-white;
          }
        }
      }
    }
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.flatpickr-disabled.today:focus {
    color: rgba(57,57,57,0.3);
    background: none;
  }

  &.flatpickr-disabled {
    border: 0;
    font-weight: normal;

    &:hover {
      border: 0;

      .date-svg {
        .shape {
          fill: $bolt-color-white;
        }

        .inner-stroke {
          stroke: $bolt-color-white;
        }
      }
    }

    &:focus {
      .date-svg {
        .inner-stroke {
          stroke: $bolt-color-white;
        }
      }
    }
  }

  &.today {
    border: 0;

    &:hover:not(:active, .selected, .flatpickr-disabled), &:focus:not(:active, .selected, .flatpickr-disabled){
      background: none; // flatpickr override
      color: $bolt-color-blue; // flatpickr override
    }

    &:not(:hover, :focus, :active, .selected), &.flatpickr-disabled{
      .date-svg .inner-stroke {
        stroke: $bolt-color-rebrand-dark-gray;
        stroke-width: 1;
      }
    }
  }

  &.important-date {
    .diamond {
      margin-left: -2px;
      width: auto;
    }

    .date-svg {
      .shape {
        fill: $bolt-color-rebrand-pale-gray;
      }

      .inner-stroke {
        stroke: $bolt-color-white;
        stroke-width: 1;
      }
    }

    &.flatpickr-disabled {
      &:hover,
      &:focus {
        .date-svg {
          .shape {
            fill: $bolt-color-rebrand-pale-gray;
          }

          .inner-stroke {
            stroke: $bolt-color-white;
          }
        }
      }
    }

    &.today {
      .date-svg .inner-stroke {
        stroke: $bolt-color-rebrand-dark-gray;
      }
    }

    &:hover {
      .date-svg {
        .inner-stroke {
          stroke: $bolt-color-rebrand-vibrant-blue;
          stroke-width: 1;
        }
      }
    }

    &.selected {
      .date-svg {
        .shape {
          fill: $bolt-color-rebrand-vibrant-blue;

          &:hover {
            fill: $bolt-color-rebrand-vibrant-blue;
          }
        }

        .inner-stroke {
          stroke: $bolt-color-white;
        }
      }

      &:hover {
        .date-svg {
          .inner-stroke {
            stroke: $bolt-color-white;
          }
        }
      }
    }

    &:focus {
      .date-svg {
        .inner-stroke {
          stroke: #1E6FD2;
        }
      }
    }

    &:active {
      .date-svg {
        .shape {
          fill: $bolt-color-rebrand-dark-blue;
        }

        .inner-stroke {
          stroke: $bolt-color-rebrand-dark-blue;
          stroke-width: 1;
        }
      }
    }
  }
}
