
.bolt-notification-wc--text.bolt-notification,.bolt-notification-wc--text.bolt-alert{
    padding: $bolt-spacing-lg; 
    @include media-breakpoint-down('sm'){
        padding: $bolt-spacing-md;
    }
}
.bolt-notification-wc--icon.bolt-notification,.bolt-notification-wc--icon.bolt-alert {
    padding: $bolt-spacing-lg $bolt-spacing-md; 
    @include media-breakpoint-down('sm'){
        padding: $bolt-spacing-md;
    }
}