.bolt-typography{
    @each $name, $value in $bolt-backgrounds{
        .bolt-background-#{$name}{
            background-color: $value;
            @if str-index($name , 'medium-25'){
                $dark: map-get($bolt-colors, 'brand-' + str-replace($name, 'medium-25', dark));
                color: $dark;
                a,a:visited{
                    color: $dark;
                    border-bottom: 1px solid $dark;
                    &:active{
                      border-bottom: 1px solid $dark;
                    }
                    &:hover,&:focus{
                      color: $dark;
                      border-color: transparent;
                    }
                }
            }
            @else if (color-contrast($value, $bolt-color-black, $bolt-color-white) == $bolt-color-white){
                color: $bolt-color-white;
                a,a:visited{
                    color: $bolt-color-white;
                    border-bottom: 1px solid $bolt-color-white;
                    &:active{
                      border-bottom: 1px solid $bolt-color-white;
                    }
                    &:hover,&:focus{
                      color: $bolt-color-white;
                      border-color: transparent;
                    }
                }
            }
            @else {
                color: $bolt-color-black;
                a,a:visited{
                    color: $bolt-text-link-default;
                    border-bottom: 1px solid $bolt-text-link-default;
                    &:active{
                      color: $bolt-text-link-default;
                      border-bottom: 1px solid $bolt-text-link-default;
                    }
                    &:hover,&:focus{
                        color: $bolt-text-link-hover;
                        border-color: transparent;
                    }
                }   
            }
        }
    }
}