bolt-contextual-help {
  img {
    max-width: 100%; 
    display: block;
  }
  @each $name, $bolt-background-value in $bolt-backgrounds{
    @if(should-output-background($name)){
      @if(map-has-key($bolt-interactive-colors, $name)){ 
        $bg: map-get($bolt-interactive-colors, $name);

        .bolt-background-#{$name} & .bolt-contextual-help-wc--trigger
          #{ if(str-index($name, "-medium-25"), ", &--tertiary.bolt-button-wc--color-#{str-replace($name, "-medium-25", "")}", "") }
          {
            .bolt-contextual-help-wc--label {
              color: text-contrast($bolt-background-value,
                $bg,
                $bolt-color-white);
              border-bottom: 1px dashed color-contrast($bolt-background-value,
                $bg,
                $bolt-color-white);
          }
          .bolt-icon path{
            fill: text-contrast($bolt-background-value,
              $bg,
              $bolt-color-white);
          }
        }
      }
    }
  }
}