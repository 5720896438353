.inline-help {
    color: $brand-color-blue-accent;
    display: inline-block;
    margin-left:.25em;
    vertical-align: text-top;
    &:hover {
        color: $text-color;
        text-decoration: none;
    }
    
    &:focus {
        color: $text-color;
        text-decoration: none;
    }

}

// Light on dark
.inline-help-inverse {
    color: $white;
    display: inline-block;
    margin-left:.25em;
    vertical-align: text-top;
    
    &:hover {
        color: $brand-color-gray-cool4;
        text-decoration: none;
    }
    
    &:focus {
        color: $brand-color-gray-cool4;
        text-decoration: none;
    }
}

.inline-help__content {
    display: inline-block;
    font-size: 1rem;
    padding: $spacer;
    border-left: solid 1px $brand-color-gray-cool4;
    margin: 5px 0 10px 0;
}

// Limit width of content for wide screens
@include media-breakpoint-up(md) {
    .inline-help__content {
        max-width: 75%;   
    }
}

.inline-help__content-container {
    max-height: 0;
    overflow: hidden;
    transition: .35s;
}
    
.inline-help__content-container.open { 
      max-height:10rem;
}