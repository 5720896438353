.bolt-tile-group-wc {

    bolt-tile {
        padding-left: 1rem;
        padding-right: 1rem; 
        min-width: 250px; 
        flex: 0 0 100%; 
    }
    
    &.tile-col-2 bolt-tile {
        flex: 0 0 50%; 
        max-width: 50%;
    }

    &.tile-col-3 bolt-tile {
        flex: 0 0 33%; 
        max-width: 33%; 
    }

    &.tile-col-4 bolt-tile {
        flex: 0 0 25%; 
        max-width: 25%; 
    }
}