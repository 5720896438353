// Sets appropriate white or black foreground based on background color
// http://www.w3.org/TR/AERT#color-contrast
// http://codepen.io/davidhalford/pen/wlDxL
@mixin text-contrast($bg) {
  $color-brightness: round((red($bg) * 299) + (green($bg) * 587) + (blue($bg) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < ($light-color/2){
    color: #fff;
  }

  @else {
    color: #000;
  }
}