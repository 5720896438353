$brand-color-nwblue:            #1c57a5;

$brand-color-blue-dark:         #003b5c;
$brand-color-blue-medium:       #326295;
$brand-color-blue-accent:       #0072cf;

$brand-color-blue:              $brand-color-blue-medium; //TODO: refactor this out

$brand-color-blue-muted-dark:   #253746;
$brand-color-blue-muted-medium: #5e9cae;
$brand-color-blue-muted-accent: #5bc6e8;

$brand-color-teal-dark:         #004851;
$brand-color-teal-medium:       #007d8a;
$brand-color-teal-accent:       #30cdd7;

$brand-color-teal-muted-dark:   #3e5d57;
$brand-color-teal-muted-medium: #279989;
$brand-color-teal-muted-accent: #40dac5;

$brand-color-green-dark:        #36573b;
$brand-color-green-medium:      #3a913f;
$brand-color-green-accent:      #58c140;

$brand-color-yellow-dark:       #523A1F;
$brand-color-yellow-medium:     #CBA052;
$brand-color-yellow-accent:     #FDBA2E;

$brand-color-orange-dark:       #7B4931;
$brand-color-orange-medium:     #AB5925;
$brand-color-orange-accent:     #E85B16;

$brand-color-red-dark:          #5D2A2C;
$brand-color-red-medium:        #9E2A2B;
$brand-color-red-accent:        #D32426;

$brand-color-fuchsia-dark:      #672146;
$brand-color-fuchsia-medium:    #A83D72;
$brand-color-fuchsia-accent:    #D644B3;

$brand-color-purple-dark:       #403A60;
$brand-color-purple-medium:     #6F5091;
$brand-color-purple-accent:     #8E4EA6;

$brand-color-gray-cool1:        #E0E1DD;
$brand-color-gray-cool2:        #D0D0CE;
$brand-color-gray-cool3:        #C9CAC8;
$brand-color-gray-cool4:        #BCBDBC;
$brand-color-gray-cool5:        #B1B3B3;
$brand-color-gray-cool6:        #ADAFAF;
$brand-color-gray-cool7:        #8B8D8E;
$brand-color-gray-cool8:        #87898B;
$brand-color-gray-cool9:        #747678;
$brand-color-gray-cool10:       #63666A;
$brand-color-gray-cool11:       #4D4F53;

$brand-color-gray-warm1:        #E0DED8;
$brand-color-gray-warm2:        #CBC4BC;
$brand-color-gray-warm3:        #C7C2BA;
$brand-color-gray-warm4:        #B7B1A9;
$brand-color-gray-warm5:        #ACA39A;
$brand-color-gray-warm6:        #A59D95;
$brand-color-gray-warm7:        #968C83;
$brand-color-gray-warm8:        #8B8178;
$brand-color-gray-warm9:        #706760;
$brand-color-gray-warm10:       #625B54;
$brand-color-gray-warm11:       #544B44;

$text-color:                    #333333;
$white:                         #FFFFFF;
$text-inverse-color:            #FFFFFF;
$btn-primary:                   $brand-color-blue;
$btn-secondary:                 #f2f2f2;

$btn-outline-inverse-color:     #e7e7e7;

$brand-primary:         $brand-color-blue-medium; // blue
$brand-secondary:       $brand-color-teal-medium; // teal
$brand-accent:          $brand-color-blue-dark; // navy
$link-color:            $brand-color-blue-accent;

$brand-color-array: ("white", $white),
                    ("text-inverse", $text-inverse-color),

                    ("nwblue", $brand-color-nwblue),

                    ("blue-dark", $brand-color-blue-dark),
                    ("blue",$brand-color-blue-medium),
                    ("blue-accent",$brand-color-blue-accent),

                    ("blue-muted-dark",$brand-color-blue-muted-dark),
                    ("blue-muted",$brand-color-blue-muted-medium),
                    ("blue-muted-accent",$brand-color-blue-muted-accent),

                    ("teal-dark",$brand-color-teal-dark),
                    ("teal",$brand-color-teal-medium),
                    ("teal-accent",$brand-color-teal-accent),

                    ("teal-muted-dark",$brand-color-teal-muted-dark),
                    ("teal-muted",$brand-color-teal-muted-medium),
                    ("teal-muted-accent",$brand-color-teal-muted-accent),
                    
                    ("green-dark",$brand-color-green-dark),
                    ("green",$brand-color-green-medium),
                    ("green-accent",$brand-color-green-accent),

                    ("yellow-dark",$brand-color-yellow-dark),
                    ("yellow",$brand-color-yellow-medium),
                    ("yellow-accent",$brand-color-yellow-accent),

                    ("orange-dark",$brand-color-orange-dark),
                    ("orange",$brand-color-orange-medium),
                    ("orange-accent",$brand-color-orange-accent),

                    ("red-dark",$brand-color-red-dark),
                    ("red",$brand-color-red-medium),
                    ("red-accent",$brand-color-red-accent),

                    ("fuchsia-dark",$brand-color-fuchsia-dark),
                    ("fuchsia",$brand-color-fuchsia-medium),
                    ("fuchsia-accent",$brand-color-fuchsia-accent),

                    ("purple-dark",$brand-color-purple-dark),
                    ("purple",$brand-color-purple-medium),
                    ("purple-accent",$brand-color-purple-accent),

                    ("neutral-cool1",$brand-color-gray-cool1),
                    ("neutral-cool2",$brand-color-gray-cool2),
                    ("neutral-cool3",$brand-color-gray-cool3),
                    ("neutral-cool4",$brand-color-gray-cool4),
                    ("neutral-cool5",$brand-color-gray-cool5),
                    ("neutral-cool6",$brand-color-gray-cool6),
                    ("neutral-cool7",$brand-color-gray-cool7),
                    ("neutral-cool8",$brand-color-gray-cool8),
                    ("neutral-cool9",$brand-color-gray-cool9),
                    ("neutral-cool10",$brand-color-gray-cool10),
                    ("neutral-cool11",$brand-color-gray-cool11),

                    ("neutral-warm1",$brand-color-gray-warm1),
                    ("neutral-warm2",$brand-color-gray-warm2),
                    ("neutral-warm3",$brand-color-gray-warm3),
                    ("neutral-warm4",$brand-color-gray-warm4),
                    ("neutral-warm5",$brand-color-gray-warm5),
                    ("neutral-warm6",$brand-color-gray-warm6),
                    ("neutral-warm7",$brand-color-gray-warm7),
                    ("neutral-warm8",$brand-color-gray-warm8),
                    ("neutral-warm9",$brand-color-gray-warm9),
                    ("neutral-warm10",$brand-color-gray-warm10),
                    ("neutral-warm11",$brand-color-gray-warm11);

@each $colorname, $color in $brand-color-array {
  .nw-bg-#{$colorname}, .nw-bg-#{$colorname}:after {
    background-color: $color;
    @include text-contrast($color);
  }

  .nw-fg-#{$colorname} {
    color: $color;
  }
}

.nw-bg-white { background:#fff; }
.nw-bg-black { background:#000; color:#fff; }

// Primary
.nw-bg-primary {
	background:$brand-primary;
  color:#fff;
  & a,& a:focus,& a:hover{
    color:#fff;
  }
}

.nw-bg-secondary {
	background:$brand-secondary;
  color:#333;
  & a,& a:focus,& a:hover{
    color:#333;
  }
}

.nw-bg-accent {
	background:$brand-accent;
  color:#fff;
  & a,& a:focus,& a:hover{
    color:#fff;
  }
}

// Foregrounds

.nw-fg-white { color:#fff; }
.nw-fg-black { color:#333; }
.nw-fg-link { color: $link-color; }

// Primary
.nw-fg-primary { color:$brand-primary; }
.nw-fg-secondary { color:$brand-secondary; }
.nw-fg-accent { color:$brand-accent; }

// tints

.nw-bg-tint-25{
  background-image:linear-gradient(rgba(255,255,255,0.75), rgba(255,255,255,0.75));
}
.nw-bg-tint-50{
  background-image:linear-gradient(rgba(255,255,255,0.50), rgba(255,255,255,0.50));
}
.nw-bg-tint-75{
  background-image:linear-gradient(rgba(255,255,255,0.25), rgba(255,255,255,0.25));
}