bolt-tab{
    border: 1px solid $bolt-color-neutral-72;
    //border-top: 0;
    left: 0;
    opacity: 1;
    right: 0;
    transition: $bolt-transition-timing-med;
    width: auto;

    &:active {
        box-shadow: none;
        outline: none;
    }
}

bolt-tab[hidden] {
    opacity: 0;
    position: absolute;
    display: none;
}
@each $name, $value in $bolt-backgrounds{
    @if (color-contrast($value, $bolt-color-black, $bolt-color-white) == $bolt-color-white){
        .bolt-background-#{$name} bolt-tab-group .bolt-tab-group-wc--label-container__block.bolt-tab-group{
            background: $bolt-color-white;
            opacity: 75%; 
        }
    }
    .bolt-background-#{$name} bolt-tab-group .bolt-tab-group-wc--header__left-bg.bolt-tab-group{
        background-image: linear-gradient(to right, $value 10%, rgba($value, 0));
    }
    .bolt-background-#{$name} bolt-tab-group .bolt-tab-group-wc--header__right-bg.bolt-tab-group{
        background-image: linear-gradient(to left, $value 10%, rgba($value, 0));
    }

}